import axios from "axios"

const TodoService = {
    SaveTodo(name, todo){
        return axios.post("https://tasklistops.azurewebsites.net/api/TaskListOps?code=faaLOLRdUxkUnVaifvrXMLO4npLuZHvcL/YWhe4PDbNd490HLYlWaw==&name="+name, todo).then(response => response.data)
    },
    LoadTodo(name){
        return axios.get("https://tasklistops.azurewebsites.net/api/TaskListOps?code=faaLOLRdUxkUnVaifvrXMLO4npLuZHvcL/YWhe4PDbNd490HLYlWaw==&name="+name).then(response => response.data)
    }
}

export default TodoService