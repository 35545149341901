import React, { Component } from 'react'

export default class TodoItem extends Component {
    render() {
        const {task, handleDelete, handleDoneTask, completed, dragHandleProps} = this.props

        return (
            <li className="list-group-item d-flex justify-content-between my-2">
                <span className={`drag-anchor mx-2`} {...dragHandleProps} >
                    <i className={'fa fa-bars'} />
                </span>
                <span className={`mx-2 ${completed ? 'text-success' : 'text-secondary'}`} onClick={() => handleDoneTask(task)}>
                    <i className={`${completed ? 'far fa-check-square' : 'far fa-square'}`} />
                </span>
                <h6 className={`mt-1 mb-0 w-100 align-middle ${completed ? 'completed-task' : ''}`} onClick={() => handleDoneTask(task)}>{task}</h6>
                <div className="todo-icon">
                <span 
                    className="mx-2 text-danger"
                    onClick={handleDelete}
                >
                    <i className="fas fa-trash" />
                </span>
                </div>
            </li>
        )
    }
}
