import React, { Component, Fragment } from 'react'
import TodoItem from './TodoItem'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export default class TodoList extends Component {
    constructor(props){
        super(props);
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    onDragEnd(event){
        this.props.handleReorder(event.source.index, event.destination.index)
    }

    render() {
        const {
            items,
            updateTodosToShow,
            handleDelete,
            handleDoneTask,
            handleReorder,
            handleClearCompleted,
        } = this.props

        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-4">
                        <button 
                            type="button"
                            className="btn btn-info btn-block mt-1"
                            onClick={() => updateTodosToShow("all")}
                        >
                            All
                        </button>
                    </div>
                    <div className="col-md-4">
                        <button 
                            type="button"
                            className="btn btn-info btn-block mt-1"
                            onClick={() => updateTodosToShow("done")}
                        >
                            Done
                        </button>
                    </div>
                    <div className="col-md-4">
                        <button 
                            type="button"
                            className="btn btn-info btn-block mt-1"
                            onClick={() => updateTodosToShow("todo")}
                        >
                            Todo
                        </button>
                    </div>
                </div>


                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        >
                        {items && items.map((item, index) => (
                            <Draggable key={item.task} draggableId={item.task} index={index}>
                            {(provided, snapshot) => (
                                <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                >
                                <TodoItem
                                    key={item.task}
                                    task={item.task}
                                    completed={item.isCompleted}
                                    handleDelete={() => handleDelete(item.task)}
                                    handleDoneTask={handleDoneTask}
                                    dragHandleProps={provided.dragHandleProps}
                                />
                                </div>
                            )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                        </div>
                    )}
                    </Droppable>
                </DragDropContext>

                <div className="row mt-4">
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                        <button 
                            type="button"
                            className="btn btn-danger btn-block mt-1"
                            onClick={handleClearCompleted}
                        >
                            Reset
                        </button>
                    </div>
                </div>
            </Fragment>
        )
    }
}
