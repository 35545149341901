import React, { Component } from 'react'
import uuid from 'uuid'
import TodoInput from './components/TodoInput'
import TodoList from './components/TodoList'
import TodoService from './services/TodoService'

class App extends Component {
	constructor(props) {
		super(props)
		this.state={
			name:window.location.href.split('/').at(-1),
			items: [],
			itemsToShow: "all",
			id: uuid(),
			item: '',
			editItem: false,
		}

		this.load = this.load.bind(this);
		this.handleReorder = this.handleReorder.bind(this);
        this.load();
	}

    load(){
        TodoService.LoadTodo(this.state.name).then(data => {
            this.setState({items: data.items });
        })
    }

	handleChange = event => {
		this.setState({
			item: event.target.value
		})
	}

	handleSubmit = event => {
		event.preventDefault()
		
		const newItem = {
			task: this.state.item,
			isCompleted: false,
			index: this.state.items.length
		}

		const filteredItems = this.state.items.filter(item => item.task === newItem.task)
		if(filteredItems.length === 0) //task not already in list
		{
			const updatedItems = [...this.state.items, newItem]

			TodoService.SaveTodo(this.state.name,updatedItems).then(data => {
				this.setState({items: data.items, item: ''})
			})
		}
	}

	handleReorder(srcIndex, destIndex){
		let reorderedItems=this.state.items;

		if(destIndex > srcIndex){
			for (var i = 0; i <= destIndex; i++){
				if(i !== srcIndex)
					reorderedItems[i].index-=1;
				else
					reorderedItems[i].index = destIndex;
			}
		}
		else{
			for (var i = destIndex; i < reorderedItems.length; i++){
				if(i !== srcIndex)
					reorderedItems[i].index+=1;
				else
					reorderedItems[i].index = destIndex;
				
			}
		}

		reorderedItems.sort((a,b) => (a.index > b.index) ? 1 : - 1);

		TodoService.SaveTodo(this.state.name,reorderedItems).then(data => {
			this.setState({items: data.items, item: ''})
		})
	}

	updateTodosToShow = string => {
		this.setState({
			itemsToShow: string
		});
	};

	handleClearCompleted = () => {
		let updatedItems = this.state.items.map(item => {
			item.isCompleted = false;
			return item;
		});

		TodoService.SaveTodo(this.state.name,updatedItems).then(data => {
			this.setState({items: data.items, item: ''})
		})
	}

	handleDoneTask = (task, completed) => {
		const filteredItems = this.state.items.map(item => {
			item.task === task && (item.isCompleted = !item.isCompleted)
			return item
		})

		TodoService.SaveTodo(this.state.name,filteredItems).then(data => {
			this.setState({items: data.items, item: ''})
		})
	}

	handleDelete = task => {
		const filteredItems = this.state.items.filter(item => item.task !== task)

		TodoService.SaveTodo(this.state.name,filteredItems).then(data => {
			this.setState({items: data.items, item: ''})
		})
	}

	render() {
		let items = []

		if (this.state.itemsToShow === "all") {
			items = this.state.items;
		} else if (this.state.itemsToShow === "todo") {
			items = this.state.items.filter(item => !item.completed);
		} else if (this.state.itemsToShow === "done") {
			items = this.state.items.filter(item => item.completed);			
		}

		return (
			<div className="container">
				<div className="row">
					<div className="col-10 col-md-8 mx-auto mt-4">
						<TodoInput
							item={this.state.item}
							handleChange={this.handleChange}
							handleSubmit={this.handleSubmit}
						/>
						<h3 className="text-center">
							{this.state.name}
						</h3>
						<TodoList
							items={items}
							filterDoneTasks={this.filterDoneTasks}
							handleDelete={this.handleDelete}
							handleEdit={this.handleEdit}
							handleDoneTask={this.handleDoneTask}
							updateTodosToShow={this.updateTodosToShow}
							handleReorder={this.handleReorder}
							handleClearCompleted={this.handleClearCompleted}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default App;
