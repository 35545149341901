import React, {Component} from 'react'
//import CreatableSelect from 'react-select/creatable';

export default class TodoInput extends Component {
    render(){
        const {item, handleChange, handleSubmit, editItem} = this.props

        return (
            <div className="card card-body my-3">
                <form onSubmit={handleSubmit}>

                    {/* <div className="input-group">
                        <div className="input-group-prepend">
                            <div className="input-group-text bg-info text-white">
                                <i className="fas fa-user" />
                            </div>
                        </div>

                        <CreatableSelect
                            className='form-control input-select'
                            isClearable
                            onChange={this.handleChange}
                            onInputChange={this.handleInputChange}
                            options={[{label:'a'},{label:'b'}]}
                        />

                    </div> */}

                    <div className="input-group">
                        <div className="input-group-prepend">
                            <div className="input-group-text bg-info text-white">
                                <i className="fas fa-book" />
                            </div>
                        </div>

                        <input
                            type="text"
                            className="form-control"
                            placeholder="New Todo"
                            value={item}
                            onChange={handleChange}
                        />
                    </div>

                    <button 
                        type="submit"
                        className={`btn btn-block mt-3 ${editItem ? 'btn-success' : 'btn-info'}`}
                    >
                        {editItem ? 'Edit task' : 'Add new task'}
                    </button>
                </form>
            </div>
        )
    }
}

